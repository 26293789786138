export default {
  methods: {
    fetchData() {
      this.loading = true;
      this.$store
        .dispatch('SampleTracking/getSampleDetail', this.id)
        .then(() => {
          this.$store.dispatch('File/getData', {
            data: this.sampleDetail.sample_pic_url,
            type: 'pic',
          });
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loading = false));

      Promise.all([this.fetchTestOrder(), this.fetchEvaluationOrder(), this.fetchComparisonOrder()]);
    },
    fetchTestOrder() {
      this.loadingTestOrders = true;
      this.$store
        .dispatch('SampleTracking/getSampleDetailTestOrder', this.id)
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loadingTestOrders = false));
    },
    fetchEvaluationOrder() {
      this.loadingEvaluationOrders = true;
      this.$store
        .dispatch('SampleTracking/getSampleDetailEvaluationOrder', this.id)
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loadingEvaluationOrders = false));
    },
    fetchComparisonOrder() {
      this.loadingComparison = true;
      this.$store
        .dispatch('SampleTracking/getSampleDetailEvaluationComparisonOrder', this.id)
        .catch(err => this.$toast.error(err.message))
        .finally(() => (this.loadingComparison = false));
    },

    viewTrackingInfo(val) {
      if (this.sampleDetail.is_return_requested && this.sampleDetail.is_returned) {
        val.loading = true;
        this.$store
          .dispatch('SampleTracking/getShippingItems', this.id)
          .then(data => {
            if (data.length > 0) {
              this.dialogShipmentTrackingInfo = true;
            } else {
              this.showNoShipping();
            }
          })
          .catch(err => this.$toast.error(err.message))
          .finally(() => (val.loading = false));
      } else {
        this.showNoShipping();
      }
    },
    showNoShipping() {
      this.$refs.alert.open({
        type: 'confirm',
        title: `${this.$t('content.no_shipping_t')}`,
        message: `${this.$t('content.no_shipping_st')}`,
        icon: 'no-shipping',
        btnAgreeText: 'OK',
      });
    },
    viewSubmissionForm(val) {
      val.loading = true;
      this.$store
        .dispatch('SampleTracking/getSampleDetailAttachment', this.id)
        .then(data => {
          if (data.length > 0) {
            this.sampleReports = data;
            this.dialogSampleReportViewFile = true;
            this.attachmentTitle = this.$t('content.submission_form');
            this.dialogSampleReportTitle = this.$t('content.submission_form');
            this.dialogSampleReport = true;
            this.dialogSampleReportDisabled = true;
          } else {
            this.$refs.alert.open({
              type: 'confirm',
              title: `${this.$t('content.no_attachment_t')}`,
              message: `${this.$t('content.no_attachment_st')}`,
              icon: 'no-submission',
              btnAgreeText: 'OK',
            });
          }
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (val.loading = false));
    },
    viewDeliverable(val) {
      val.loading = true;
      this.$store
        .dispatch('SampleTracking/getReports', this.id)
        .then(data => {
          if (data.length > 0) {
            this.sampleReports = data;
            this.dialogSampleReportViewFile = false;
            this.dialogSampleReportTitle = this.$t('content.sample_report');
            this.dialogSampleReport = true;
            this.dialogSampleReportDisabled = false;
          } else {
            this.$refs.alert.open({
              type: 'confirm',
              title: `${this.$t('content.no_deliv_t')}`,
              message: `${this.$t('content.no_deliv_st')}`,
              icon: 'no-reports',
              btnAgreeText: 'OK',
            });
          }
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (val.loading = false));
    },
    viewOrderConfirmations(val) {
      val.loading = true;
      this.$store
        .dispatch('SampleTracking/getOrderConfirmations', this.id)
        .then(data => {
          if (data.length > 0) {
            this.sampleReports = data;
            this.dialogSampleReportViewFile = true;
            this.attachmentTitle = this.$t('content.sample_confirmation');
            this.dialogSampleReportTitle = this.$t('content.sample_confirmation');
            this.dialogSampleReport = true;
            this.dialogSampleReportDisabled = false;
          } else {
            this.$refs.alert.open({
              type: 'confirm',
              title: `${this.$t('content.no_confirm_t')}`,
              message: `${this.$t('content.no_confirm_st')}`,
              icon: 'no-order',
              btnAgreeText: 'OK',
            });
          }
        })
        .catch(err => this.$toast.error(err.message))
        .finally(() => (val.loading = false));
    },
    shareSample(val) {
      val.loading = true;
      const { id: object_id, content_type } = this.sampleDetail;
      const param = { object_id, content_type };
      this.$store
        .dispatch('SampleTracking/getWorkOrderOwner', param)
        .then(() => (this.dialogShare = true))
        .catch(err => this.$toast.error(err.message))
        .finally(() => (val.loading = false));
    },
    showFile(val) {
      this.attachmentItem = val;
      this.dialogDocument = true;
    },
    statusTitle(text) {
      const sts = this.stages.find(stage => stage.value === text);
      if (sts) return sts.display;
      else return text;
    },
    onDetail({ item, mode }) {
      this.dialogTestDetailMode = mode;
      this.dialogTestDetail = true;
      this.testItems = item;
    },
    onRetest(val) {
      this.selectedTestOrder = val;
      this.dialogReTest = true;
    },
    onCompare({ id, testId }) {
      this.$router.replace({
        name: 'SampleComparison',
        params: { id: testId },
        query: { test_orders: id.toString() },
      });
    },
  },
};
