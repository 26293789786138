export default {
  methods: {
    downloadMixin_download(blob, config = {}) {
      const { name, type } = {
        name: 'Filename',
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        ...config,
      };

      const fileRes = window.URL.createObjectURL(new Blob([blob], { type }));
      const fdl = document.createElement('a');
      fdl.href = fileRes;
      fdl.setAttribute('download', name);
      document.body.appendChild(fdl);
      fdl.click();
    },
  },
};
